@import url("https://fonts.googleapis.com/css2?family=Fuggles&family=Lato:wght@100;300;700&family=Poppins:wght@200;400;500;600&family=Public+Sans:wght@100;200;400;500&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

header {
  /* should not merge while shrinking the size */
  z-index: 10001 !important;
}
.section-container {
  @apply max-w-screen-2xl container mx-auto xl:px-24 px-4;
}

 .subtitle {
  @apply text-red uppercase tracking-wide font-medium text-lg;
}

.title {
  @apply text-4xl md:text-5xl font-bold my-2 md:leading-snug leading-snug;
}

.heartStar {
  border-radius: 0px 28px 0px 28px;
}
.active {
  color: rgb(225, 225, 227);
  background: blue;
  border-radius: 5px;
} 